const envvariable = {
    'local': {
        "REACT_APP_API_URL": 'http://localhost:8080',
        "REACT_APP_API_URL_SP": "https://terra-api-qa.stylishop.store",
        "REACT_APP_GCS_BUCKET_NAME": "supplier-storage-new"
      },
    'development': {
        "REACT_APP_API_URL": 'https://auriga-api-dev.stylishop.store',
        "REACT_APP_API_URL_SP": "https://terra-api-dev.stylishop.store",
        "REACT_APP_GCS_BUCKET_NAME": "supplier-storage-new"
    },
    "qa": {
        "REACT_APP_API_URL": 'https://auriga-api-qa.stylishop.store',
        "REACT_APP_API_URL_SP": "https://terra-api-qa.stylishop.store",
        "REACT_APP_GCS_BUCKET_NAME": "supplier-storage-new"
    },
    "production": {
        "REACT_APP_API_URL": 'https://auriga-production-nrb4qa3iua-df.a.run.app',
        "REACT_APP_API_URL_SP": "https://terra-api-prod.stylishop.store",
        "REACT_APP_GCS_BUCKET_NAME": "auriga-storage"
      }
  }
   
  exports.getEnvvariable = () => {
    const envvar = process.env.REACT_APP_ENV;
    return  envvariable[envvar];
  }